import { Binding } from './ContentStructure'
import {
  PhysicalPosition,
  PhysicalSize,
  Vec2,
  Rotation,
  transform2D,
  RectMM,
  BoundingBox,
  mm2px,
  RectF,
  PolygonRect2D,
} from './ImageViewerCommon'
import { SearchHitContent, SearchHitPin } from './SearchHitInfo'
import { DrawResult, VisualElement } from './VisualElement'

/**
 * 描画領域クリック位置列挙体
 */
const ClickAreas = {
  /**
   * クリックされていない
   */
  None: 0,
  /**
   * 領域中央＝メニュー表示
   */
  Center: 1,
  /**
   * 領域左端＝ページめくり
   */
  Left: 2,
  /**
   * 領域右端＝ページめくり
   */
  Right: 3,
}

type ClickAreas = typeof ClickAreas[keyof typeof ClickAreas]

class MetaSearchHitPin {
  public id = -1
  public iconName = ''
  // ここではピクセル座標で格納する
  public position = new PhysicalPosition()
  // このピンが設置されているページインデックス
  public pageIndex = -1

  public clone () {
    const pin = new MetaSearchHitPin()
    pin.id = this.id
    pin.iconName = '' + this.iconName
    pin.position.copy(this.position)
    pin.pageIndex = this.pageIndex
    return pin
  }
}

/**
 * メタレイアウト情報クラス
 */
class MetaLayout {
  /**
   * 直近描画された状態の最終的なクリッピングマスクのパス
   */
  private clippingAreaPaths_: Array<PolygonRect2D> = new Array<PolygonRect2D>()

  private searchiHitPins_: Array<MetaSearchHitPin> = []

  /**
   * 状態をリセットする
   */
  public reset () {
    this.clippingAreaPaths_ = []
    this.searchiHitPins_ = []
  }

  /**
   * クリッピングマスクパスを追加する
   * @param paths
   */
  public addClippingPath (paths: Array<PolygonRect2D>, directOffset?: PhysicalPosition) {
    for (let i = 0; i < paths.length; ++i) {
      this.clippingAreaPaths_.push(paths[i].clone(directOffset))
    }
  }

  /**
   * 検索ヒットピンを追加する
   * @param pin
   */
  public addSearchPins (metaPins: Array<MetaSearchHitPin>) {
    for (let i = 0; i < metaPins.length; ++i) {
      this.searchiHitPins_.push(metaPins[i])
    }
  }

  /**
   *
   * @param context
   * @returns -1=致命的なエラー、0=描画成功、1=アニメーションする必要がなく成功、2=再描画が必要、3=コマのレンダリングがされていない
   */
  public drawSearchHitPinToContext (context: CanvasRenderingContext2D, selectedID: number, selectidPinScale: number, selectedPinMaxScale: number, currentPageIndex: number): number {
    const pinSize = 24 * devicePixelRatio

    // const stockStyle = context.fillStyle
    // const stockAlpha = context.globalAlpha

    let needToContinueAnim = false
    if (this.searchiHitPins_.length <= 0) {
      // コマのレンダリングが終わっていない
      return 3
    }
    for (let i = 0; i < this.searchiHitPins_.length; ++i) {
      const pin = this.searchiHitPins_[i]
      const icon = SearchHitPin.getIconImage(pin.iconName)

      const isCurrent = pin.id === selectedID
      let sizeAdjust = isCurrent ? selectidPinScale : 1

      needToContinueAnim = isCurrent ? true : needToContinueAnim
      if (isCurrent && pin.pageIndex !== currentPageIndex) {
        needToContinueAnim = false
      }

      if (isCurrent && !needToContinueAnim) {
        sizeAdjust = selectedPinMaxScale
      }

      /*
      let alpha = (pin.id === selectedID ? 1.0 : 0.3)
      if (selectedID < 0) {
        alpha = 1.0
      }

      context.globalAlpha = alpha
      */

      if (icon) {
        if (icon.state === 1 && icon.img) {
          // アイコン画像の縦横比が変更された場合にも対応する
          const iw = icon.img.naturalWidth
          const ih = icon.img.naturalHeight
          let wrate = 1.0
          let hrate = 1.0

          if (iw > ih) {
            hrate = ih / iw
          } else {
            wrate = iw / ih
          }

          context.drawImage(icon.img, pin.position.x, pin.position.y - pinSize * hrate * sizeAdjust, pinSize * wrate * sizeAdjust, pinSize * hrate * sizeAdjust)
        } else if (icon.state === 0) {
          // ダウンロード完了を待つ
          // context.fillStyle = stockStyle
          // context.globalAlpha = stockAlpha
          return 2
        }
        // アイコン画像にエラーがある場合は描画をあきらめる
      } else {
        // context.fillStyle = stockStyle
        // context.globalAlpha = stockAlpha
        return 2
      }
    }

    // context.fillStyle = stockStyle
    // context.globalAlpha = stockAlpha
    return needToContinueAnim ? 0 : 1
  }

  /*
  public setClippingPathToContext(context: CanvasRenderingContext2D){
    for(let p=0; p<this.clippingAreaPaths_.length; ++p){
      context.beginPath()
      context.moveTo(this.clippingAreaPaths_[p].vertices[0].x, this.clippingAreaPaths_[p].vertices[0].y)
      for (let i = 1; i < this.clippingAreaPaths_[p].vertices.length; ++i) {
        context.lineTo(this.clippingAreaPaths_[p].vertices[i].x, this.clippingAreaPaths_[p].vertices[i].y)
      }
      context.closePath()

      //  context.clip()
      context.fillStyle = "red"
      context.fill()
     }

  }
  */
}

/**
 * 描画レイアウト管理クラス
 */
class Layouter {
  private backGroundColor_ = 'gray'
  public set backGroundColor (color: string) {
    this.backGroundColor_ = color
  }

  /**
   * キャンバスの実ピクセルサイズ
   */
  private canvasSize_ = new PhysicalSize();

  /**
   * PagePosition は
   * ・コマの回転、余白自動削除、印刷範囲切り抜き を考慮しない
   * ・本来のコマの左上の座標 を表す
   */
  private currentPagePosition_ = new PhysicalPosition();

  private currentPageScale_ = 0.0

  public get currentPageScale (): number {
    return this.currentPageScale_
  }

  public set currentPageScale (value: number) {
    this.currentPageScale_ = value
  }

  /**
   * ビューアの中心に存在する現在の表示要素上の座標
   */
  private currentElementCoordinatesOnCenterOfClientArea_ = new Vec2();
  public get currentElementCoordinatesOnCenterOfClientArea (): Vec2 {
    return this.currentElementCoordinatesOnCenterOfClientArea_.clone()
  }

  /**
   * 回転角度 0=回転しない
   */
  private currentRotation_ = new Rotation()

  /**
   * 余白自動削除を行うか否か
   */
  private enableAutoClipping_ = false

  public set enableAutoClipping (value: boolean) {
    this.enableAutoClipping_ = value
  }

  public get enableAutoClipping (): boolean {
    return this.enableAutoClipping_
  }

  /**
   * 切り抜き範囲
   */
  private currentCropping_ = new RectMM()
  private currentCroppingBuf_ = new RectMM()
  public get currentCropping (): RectMM {
    this.currentCroppingBuf_.left = this.currentCropping_.left
    this.currentCroppingBuf_.top = this.currentCropping_.top
    this.currentCroppingBuf_.right = this.currentCropping_.right
    this.currentCroppingBuf_.bottom = this.currentCropping_.bottom
    return this.currentCroppingBuf_
  }

  public get pagePosition (): PhysicalPosition {
    return this.currentPagePosition_
  }

  /**
   * バックバッファキャンバス
   */
  private backbufferCanvas_?: HTMLCanvasElement;
  /**
   * バックバッファコンテキスト
   */
  private backbufferContext_?: CanvasRenderingContext2D;

  private foreignResource_?: HTMLImageElement;

  /**
   * isFitScale() で常に ture を返すようにするか否か
   */
  private keepFitScale_ = false

  public get keepFitScale (): boolean {
    return this.keepFitScale_
  }

  public set keepFitScale (value: boolean) {
    this.keepFitScale_ = value
  }

  private currentPageBinding_: Binding = Binding.none
  public set currentPageBinding (value: Binding) {
    this.currentPageBinding_ = value
  }

  /**
   * 直近描画した状態のメタレイアウト
   */
  private currentMetaLayout_ = new MetaLayout()

  /**
   * レイアウトを初期化する
   * @param taintResrouce バックバッファキャンバスを汚染させるための外部Img要素。未指定の場合は汚染させない
   * @returns 0=エラーなし
   */
  public initLayout (taintResrouce?: HTMLImageElement): number {
    this.backbufferCanvas_ = document.createElement(
      'canvas'
    ) as HTMLCanvasElement

    this.foreignResource_ = taintResrouce

    const bbc = this.backbufferCanvas_.getContext('2d')
    if (bbc) {
      this.backbufferContext_ = bbc
      this.backbufferContext_.imageSmoothingEnabled = true
      this.backbufferContext_.imageSmoothingQuality = 'low'
      if (this.foreignResource_) {
        this.backbufferContext_.drawImage(this.foreignResource_, 0, 0, 1, 1, 0, 0, 1, 1)
      }
      return 0
    }

    return 1
  }

  /**
   * メタレイアウトをリセットする
   */
  public resetMetaLayout () {
    this.currentMetaLayout_.reset()
  }

  /**
   * ソース元 Layouter の一部ステータスをコピーする
   * @param src
   */
  public copyStateFrom (src: Layouter): void {
    this.currentRotation_.copy(src.currentRotation_)
    this.currentCropping_.copy(src.currentCropping_)
    this.enableAutoClipping_ = src.enableAutoClipping_
    this.currentPagePosition_.copy(src.currentPagePosition_)
    this.currentPageScale_ = src.currentPageScale_
    this.currentPageBinding_ = src.currentPageBinding_
  }

  /**
   * 拡大後の仮想原点を算出する
   * @param newScale スケール変更した後のスケール値
   * @param pivot スケール変更する前の拡大起点座標X HTML要素のoffsetXY
   */
  private calcVirtualOrigin_ (newScale: number, pivot?: PhysicalPosition): void {
    const p = new PhysicalPosition(this.canvasSize_.width * 0.5, this.canvasSize_.height * 0.5)
    if (pivot !== undefined && Number.isFinite(pivot.x) && Number.isFinite(pivot.y)) {
      p.x = pivot.x
      p.y = pivot.y
    }

    const originDistanceFromOrigin = new PhysicalPosition()
    originDistanceFromOrigin.x = (p.x - this.currentPagePosition_.x) / this.currentPageScale_
    originDistanceFromOrigin.y = (p.y - this.currentPagePosition_.y) / this.currentPageScale_

    const newDistanceFromOrigin = new PhysicalPosition()
    newDistanceFromOrigin.x = originDistanceFromOrigin.x * newScale
    newDistanceFromOrigin.y = originDistanceFromOrigin.y * newScale

    this.currentPagePosition_.x = -(newDistanceFromOrigin.x - p.x)
    this.currentPagePosition_.y = -(newDistanceFromOrigin.y - p.y)
  }

  public clearCanvas (context: CanvasRenderingContext2D): void {
    context.fillStyle = this.backGroundColor_
    context.fillRect(0, 0, this.canvasSize_.width, this.canvasSize_.height)
  }

  /**
   *
   * @param context 描画先コンテキスト
   * @param selectedID 現在選択中の検索ヒットピンのID
   * @returns -1=致命的なエラー、0=描画成功、1=アニメーションする必要がなく成功、2=再描画が必要、3=コマのレンダリングがされていない
   */
  public drawSearchHitInfo (context: CanvasRenderingContext2D, selectedID: number, selectedPinScale: number, selectedInMaxScale: number, currentPageIndex: number): number {
    return this.currentMetaLayout_.drawSearchHitPinToContext(context, selectedID, selectedPinScale, selectedInMaxScale, currentPageIndex)
  }

  /**
   *
   * @param context
   * @param currentPageIndex 現在のページインデックス（テンポラリ）
   * @param elements
   * @param delayDrawFunc 遅延描画コールバック
   * @param clear Canavasをクリアするか否か
   * @param searchHitPins 検索ヒット情報配列
   * @param directScale
   * @param directOffset
   * @param [onlyLowLevel=false] 低解像度タイルのみ描画するか否か
   * @param searchHitInfo 検索ヒット情報配列
   * @returns
   */
  public draw (
    context: CanvasRenderingContext2D,
    currentPageIndex: number,
    elements: Array<VisualElement | undefined>,
    delayDrawFunc: (() => void) | undefined,
    clear = true,
    searchHitPins?: Array<SearchHitContent>,
    directScale?: number,
    directOffset?: PhysicalPosition,
    onlyLowLevel = false,
    searchHitInfo?: Array<SearchHitContent>
  ): DrawResult {
    if (!this.backbufferCanvas_ || !this.backbufferContext_) return DrawResult.Failed

    this.updateElementCoordinatesOnCenterOfClientArea(elements)

    if (this.backbufferContext_ && clear) {
      this.backbufferContext_.fillStyle = this.backGroundColor_
      this.backbufferContext_.fillRect(0, 0, this.canvasSize_.width, this.canvasSize_.height)
    }

    if (clear) {
      context.fillStyle = this.backGroundColor_
      context.fillRect(0, 0, this.canvasSize_.width, this.canvasSize_.height)
    }

    if (this.foreignResource_) {
      this.backbufferContext_.drawImage(this.foreignResource_, 0, 0, 1, 1, 0, 0, 1, 1)
    }

    if (elements[0] && !elements[1]) {
      this.currentRotation_.pivot.x = 0.5
      this.currentRotation_.pivot.y = 0.5
      return this.drawElement(context, currentPageIndex, elements[0], this.enableAutoClipping_, delayDrawFunc, 0, 0, directScale, directOffset, onlyLowLevel, undefined, undefined, searchHitInfo)
    } else if (elements[0] && elements[1]) {
      // 2in1しか来ない
      let firstPage = 1
      let secondPage = 0
      if (this.currentPageBinding_ === Binding.ltr) {
        firstPage = 0
        secondPage = 1
      }

      const pLeft = elements[firstPage]
      const pRight = elements[secondPage]
      let offset = 0
      let drawn: DrawResult = DrawResult.Successed

      if (directScale === undefined) {
        directScale = this.currentPageScale_
      }

      const bbLeft = this.getElementBoundingBox([pLeft], directScale, undefined, false, this.enableAutoClipping_)
      const bbRight = this.getElementBoundingBox([pRight], directScale, undefined, false, this.enableAutoClipping_)

      const aRateX = bbLeft.width / (bbLeft.width + bbRight.width)
      const bbHeight = (bbLeft.height > bbRight.height ? bbLeft.height : bbRight.height)

      if (pLeft) {
        // 2in1の中心で回転させるように回転原点を移動させる
        this.currentRotation_.pivot.x = (0.5 / aRateX)
        this.currentRotation_.pivot.y = 0.5 * bbHeight / bbLeft.height

        // 2in1で左右ページを高さ揃えするにはオフセット値を transform する必要がある。offsetX,directOffset では役に立たない。
        const alignOffsetY = Math.min(bbLeft.height - bbRight.height, 0) * -0.5

        drawn = this.drawElement(context, currentPageIndex, pLeft, this.enableAutoClipping_, delayDrawFunc, 0, 0, directScale, directOffset, onlyLowLevel, true, alignOffsetY, searchHitInfo)

        offset = bbLeft.width
      }
      if (pRight && drawn === DrawResult.Successed) {
        // 2in1の中心で回転させるように回転原点を移動させる
        this.currentRotation_.pivot.x = 1.0 - (aRateX / 0.5)
        this.currentRotation_.pivot.y = 0.5 * bbHeight / bbRight.height

        const alignOffsetY = Math.min(bbRight.height - bbLeft.height, 0) * -0.5

        drawn = this.drawElement(context, currentPageIndex, pRight, this.enableAutoClipping_, delayDrawFunc, offset, 0, directScale, directOffset, onlyLowLevel, true, alignOffsetY, searchHitInfo)
      }

      return drawn
    } else {
      return DrawResult.Failed
    }
  }

  /**
   * 単ページを描画する
   * @param context
   * @param currentPageIndex 現在のページインデックス（テンポラリ）
   * @param page
   * @param delayDrawFunc
   * @param offsetX
   * @param offsetY
   * @param isRightPage 右に配置する要素か否か
   */
  private drawElement (
    context: CanvasRenderingContext2D,
    currentCoverPageIndex: number,
    page: VisualElement,
    clipping: boolean,
    delayDrawFunc: (() => void) | undefined,
    offsetX = 0,
    offsetY = 0,
    directScale?: number,
    directOffset?: PhysicalPosition,
    // clippingOffsetLeft = 0,
    onlyLowLevel = false,
    is2In1 = false,
    transformedOffsetY = 0,
    searchHitInfo?: Array<SearchHitContent>
  ): DrawResult {
    if (page.koma && this.backbufferCanvas_) {
      // 先に低解像度タイルを背景として描画する
      const drawn = page.draw(
        currentCoverPageIndex,
        page.koma.levelCount - 1,
        context,
        this.backbufferCanvas_,
        this.canvasSize_,
        this.currentPagePosition_.x + (directOffset ? directOffset.x : 0),
        this.currentPagePosition_.y + (directOffset ? directOffset.y : 0),
        offsetX,
        offsetY,
        directScale !== undefined ? directScale : this.currentPageScale_,
        true,
        delayDrawFunc,
        this.currentRotation_,
        clipping,
        this.currentCropping_,
        0,
        is2In1,
        transformedOffsetY,
        true,
        searchHitInfo
      )
      this.currentMetaLayout_.addClippingPath(page.currentFinalClippingPaths)//, directOffset)
      this.currentMetaLayout_.addSearchPins(page.currentMetaSearchHitPins)
      if (onlyLowLevel) {
        return drawn
      }
      if (drawn === DrawResult.Successed) {
        // 高解像度タイル
        const targetLevel = page.calcDecentLevel(page.getElementSize(this.currentPageScale_))
        // 高解像度タイルの描画が必要であれば描画する
        if (targetLevel !== page.koma.levelCount - 1) {
          return page.draw(
            currentCoverPageIndex,
            targetLevel,
            context,
            this.backbufferCanvas_,
            this.canvasSize_,
            this.currentPagePosition_.x + (directOffset ? directOffset.x : 0),
            this.currentPagePosition_.y + (directOffset ? directOffset.y : 0),
            offsetX,
            offsetY,
            directScale !== undefined ? directScale : this.currentPageScale_,
            false,
            delayDrawFunc,
            this.currentRotation_,
            clipping,
            this.currentCropping_,
            0,
            is2In1,
            transformedOffsetY,
            false,
            searchHitInfo
          )
        } else {
          // 高解像度タイルが必要ない または存在しない場合
          return DrawResult.Successed
        }
      } else {
        return drawn
      }
    }
    return DrawResult.Failed
  }

  /**
   * キャンバスサイズを設定する
   * @param width 幅実ピクセル
   * @param height 高さ実ピクセル
   */
  public setCanvasSize (width: number, height: number): void {
    this.canvasSize_.width = width
    this.canvasSize_.height = height

    if (this.backbufferCanvas_) {
      this.backbufferCanvas_.width = width
      this.backbufferCanvas_.height = height
      if (this.backbufferContext_) {
        this.backbufferContext_.imageSmoothingEnabled = true
        this.backbufferContext_.imageSmoothingQuality = 'low'
      }
    }
  }

  private calcRotatedBB (bb: BoundingBox, antiTileAngle: number): BoundingBox {
    const res = new BoundingBox()

    // 回転を考慮する
    const mat = new DOMMatrix()
    mat.translateSelf(bb.width * 0.5, bb.height * 0.5)
    mat.rotateSelf(this.currentRotation_.angle + antiTileAngle) // 引数はdegree
    mat.translateSelf(-bb.width * 0.5, -bb.height * 0.5)
    const corner = new Array<Vec2>(4)
    const point = new Vec2()

    // LT
    corner[0] = transform2D(point, mat)
    // RT
    point.x = bb.width
    corner[1] = transform2D(point, mat)
    // LB
    point.x = 0
    point.y = bb.height
    corner[2] = transform2D(point, mat)
    // RB
    point.x = bb.width
    point.y = bb.height
    corner[3] = transform2D(point, mat)

    res.left = corner[0].x
    res.right = corner[0].x
    res.top = corner[0].y
    res.bottom = corner[0].y

    for (let i = 1; i < 4; ++i) {
      const c = corner[i]
      if (res.left > c.x) {
        res.left = c.x
      }
      if (res.right < c.x) {
        res.right = c.x
      }
      if (res.top > c.y) {
        res.top = c.y
      }
      if (res.bottom < c.y) {
        res.bottom = c.y
      }
    }

    res.matrix = mat

    res.offset.left = res.left
    res.offset.top = res.top
    res.offset.right = res.right - bb.width
    res.offset.bottom = res.bottom - bb.height

    return res
  }

  /**
   * ページまたはコマの描画バウンディングボックスを返す
   *
   * @param elements
   */
  public getElementBoundingBox (elements: Array<VisualElement | undefined>, scale = 1.0, crop?: RectMM, rotation = true, autoClipping = false): BoundingBox {
    let boundingBox = new BoundingBox()

    let bbA = new BoundingBox()
    const bbB = new BoundingBox()

    const useDOETBBC = false

    const rightKomaOffset = 0

    if (!elements[0] && !elements[1]) {
      return bbA
    }

    if (elements[0] && !elements[1]) {
      const antiTiltAngle = (this.enableAutoClipping_ ? elements[0].antiTiltAngle : 0)

      bbA = elements[0].getElementSize(scale, crop)
      // bbA.width = bb.width
      // bbA.height = bb.height

      boundingBox.left = bbA.left
      boundingBox.top = bbA.top
      boundingBox.right = bbA.right
      boundingBox.bottom = bbA.bottom
      boundingBox.offset.left = bbA.offset.left
      boundingBox.offset.top = bbA.offset.top
      boundingBox.offset.right = bbA.offset.right
      boundingBox.offset.bottom = bbA.offset.bottom

      if (!crop || crop.width <= 0 || crop.height <= 0) {
        if (autoClipping && elements[0].clippingArea) {
          const clippedBB = elements[0].getClippedBoundingBox(undefined, scale)

          if (clippedBB && clippedBB.width > 0 && clippedBB.height > 0) {
            boundingBox.left = clippedBB.left
            boundingBox.top = clippedBB.top
            boundingBox.right = clippedBB.right
            boundingBox.bottom = clippedBB.bottom

            boundingBox.offset.left = clippedBB.offset.left
            boundingBox.offset.top = clippedBB.offset.top
            boundingBox.offset.right = clippedBB.offset.right
            boundingBox.offset.bottom = clippedBB.offset.bottom

            if (rotation) {
              boundingBox = this.calcRotatedBB(boundingBox, antiTiltAngle)
            }
          }
        } else {
          if (rotation) {
            const rbb = this.calcRotatedBB(bbA, antiTiltAngle)

            boundingBox.left = rbb.left
            boundingBox.top = rbb.top
            boundingBox.right = rbb.right
            boundingBox.bottom = rbb.bottom
            boundingBox.offset.left = rbb.offset.left
            boundingBox.offset.top = rbb.offset.top
            boundingBox.offset.right = rbb.offset.right
            boundingBox.offset.bottom = rbb.offset.bottom

            bbA.matrix = rbb.matrix
          }
        }
      }
    } else if (elements[0] && elements[1]) {
      // 2in1
      // 抜本的に変えます

      const bbA = elements[0].getElementSize(scale)
      const bbB = elements[1].getElementSize(scale)

      boundingBox.width = bbA.width + bbB.width
      boundingBox.height = (bbA.height > bbB.height ? bbA.height : bbB.height)

      if (crop && crop.width > 0 && crop.height > 0) {
        // 2in1時のDPIは elements[0] のものを使用する

        const cropRate = new RectF()
        const dpi = elements[0].koma!.dpi
        cropRate.left = mm2px(crop.left, dpi) * scale / boundingBox.width
        cropRate.top = mm2px(crop.top, dpi) * scale / boundingBox.height
        cropRate.right = mm2px(crop.right, dpi) * scale / boundingBox.width
        cropRate.bottom = mm2px(crop.bottom, dpi) * scale / boundingBox.height

        const oW = boundingBox.width
        const oH = boundingBox.height

        const croppedWidth =
            boundingBox.width *
            cropRate.width

        const croppedHeight =
            boundingBox.height *
            cropRate.height

        boundingBox.left = boundingBox.width * cropRate.left
        boundingBox.top = boundingBox.height * cropRate.top
        boundingBox.width = croppedWidth
        boundingBox.height = croppedHeight

        boundingBox.offset.left = boundingBox.left
        boundingBox.offset.top = boundingBox.top
        boundingBox.offset.right = oW * -(1.0 - cropRate.right)
        boundingBox.offset.bottom = oH * -(1.0 - cropRate.bottom)
      } else if (autoClipping && elements[1].clippingArea) {
        const clippedBBA = elements[0].getClippedBoundingBox(undefined, scale)
        const clippedBBB = elements[1].getClippedBoundingBox(undefined, scale)

        if (!clippedBBA || !clippedBBB) {
          if (rotation) {
            boundingBox = this.calcRotatedBB(boundingBox, 0)
          }
          return boundingBox
        }

        if (this.currentPageBinding === Binding.rtl) {
          boundingBox.left = clippedBBB.left
          boundingBox.offset.left = clippedBBB.left

          boundingBox.offset.right = clippedBBA.offset.right
        } else {
          boundingBox.left = clippedBBA.left
          boundingBox.offset.left = clippedBBA.left

          boundingBox.offset.right = clippedBBB.offset.right
        }

        boundingBox.top = (clippedBBA.top < clippedBBB.top ? clippedBBA.top : clippedBBB.top)
        // boundingBox.bottom = (clippedBBA.bottom < clippedBBB.bottom ? clippedBBA.bottom : clippedBBB.bottom)

        boundingBox.width = clippedBBA.width + clippedBBB.width
        boundingBox.height = (clippedBBA.height > clippedBBB.height ? clippedBBA.height : clippedBBB.height)

        boundingBox.offset.bottom = (clippedBBA.offset.bottom > clippedBBB.offset.bottom ? clippedBBA.offset.bottom : clippedBBB.offset.bottom)

        if (rotation) {
          boundingBox = this.calcRotatedBB(boundingBox, 0)
        }
      } else if (rotation) {
        boundingBox = this.calcRotatedBB(boundingBox, 0)
      }
    }

    return boundingBox
  }

  /**
   * 指定したスケールで現在の状態を描画したときに要素が描画される最終的な（回転、切り抜きを考慮した）Canvas内の位置とサイズを返す
   * @param elements
   * @param scale
   * @returns
   */
  public getElementPlacement (elements: Array<VisualElement | undefined>, binding: Binding, scale: number): BoundingBox {
    const res = new BoundingBox()

    const bb = this.getElementBoundingBox(elements, scale, this.currentCropping_, true, this.enableAutoClipping_)

    res.left = this.currentPagePosition_.x + bb.offset.left
    res.top = this.currentPagePosition_.y + bb.offset.top

    res.width = bb.width
    res.height = bb.height

    res.offset.left = bb.offset.left
    res.offset.top = bb.offset.top
    res.offset.right = bb.offset.right
    res.offset.bottom = bb.offset.bottom

    return res
  }

  /**
   * 指定した描画エレメントをフィット表示した際の拡大率を返す
   * @param elements
   */
  public getFitScale (elements: Array<VisualElement | undefined>, binding: Binding): number {
    let res = 1.0

    if (!elements[0] && !elements[1]) {
      return this.currentPageScale_
    }

    if (this.canvasSize_.width <= 0 || this.canvasSize_.height <= 0) {
      return 0
    }

    const totalSize = this.getElementBoundingBox(elements, 1.0, this.currentCropping_, true, this.enableAutoClipping_)

    let targetWidth = 0; let targetHeight = 0
    if (totalSize.width > 0 && totalSize.height > 0) {
      if (totalSize.width > totalSize.height) {
        res = this.canvasSize_.width / totalSize.width
      } else {
        res = this.canvasSize_.height / totalSize.height
      }
      targetWidth = totalSize.width * res
      targetHeight = totalSize.height * res

      if (targetWidth > this.canvasSize_.width) {
        res = this.canvasSize_.width / totalSize.width

        targetWidth = this.canvasSize_.width
        targetHeight = totalSize.height * res
      }
      if (targetHeight > this.canvasSize_.height) {
        res = this.canvasSize_.height / totalSize.height

        targetWidth = totalSize.width * res
        targetHeight = this.canvasSize_.height
      }
    }

    return res
  }

  public getCenterPosition (elements: Array<VisualElement | undefined>): PhysicalPosition {
    return new PhysicalPosition()
  }

  /**
   * 指定した描画ページの中心位置を直前の中心位置に移動させる
   * @param elements
   * @param prevCenterPos
   * @returns
   */
  public keepCenterPosition (elements: Array<VisualElement | undefined>, binding: Binding, prevCenterPos: PhysicalPosition): void{
    const bb = this.getElementBoundingBox(elements, this.currentPageScale_, this.currentCropping_, false)
    if (bb.width <= 0 || bb.height <= 0) {
      return
    }
    if (this.canvasSize_.width <= 0 || this.canvasSize_.height <= 0) {
      return
    }
    this.currentPagePosition_.x = this.canvasSize_.width * 0.5 - bb.width * this.currentElementCoordinatesOnCenterOfClientArea_.x
    this.currentPagePosition_.y = this.canvasSize_.height * 0.5 - bb.height * this.currentElementCoordinatesOnCenterOfClientArea_.y
  }

  /**
   * 指定した描画ページをセンタリングする
   * @param dir 0=HV, 1=H, 2=V
   */
  public centering (elements: Array<VisualElement | undefined>, binding: Binding): void {
    if (!elements[0] && !elements[1]) {
      return
    }

    if (this.canvasSize_.width <= 0 || this.canvasSize_.height <= 0) {
      return
    }

    const bb = this.getElementBoundingBox(elements, 1.0, this.currentCropping_, true, this.enableAutoClipping_)
    if (bb.width <= 0 || bb.height <= 0) {
      return
    }

    let targetWidth = bb.width
    let targetHeight = bb.height

    if (this.currentPageScale_ <= 0 || targetWidth > targetHeight) {
      this.currentPageScale_ = this.canvasSize_.width / bb.width

      targetWidth = this.canvasSize_.width
      targetHeight = bb.height * this.currentPageScale_
    }
    if (targetHeight > targetWidth) {
      this.currentPageScale_ = this.canvasSize_.height / bb.height

      targetWidth = bb.width * this.currentPageScale_
      targetHeight = this.canvasSize_.height
    }

    if (targetWidth > this.canvasSize_.width) {
      this.currentPageScale_ = this.canvasSize_.width / bb.width

      targetWidth = this.canvasSize_.width
      targetHeight = bb.height * this.currentPageScale_
    } else if (targetHeight > this.canvasSize_.height) {
      this.currentPageScale_ = this.canvasSize_.height / bb.height

      targetWidth = bb.width * this.currentPageScale_
      targetHeight = this.canvasSize_.height
    }

    this.currentPagePosition_.x =
      (this.canvasSize_.width - targetWidth) * 0.5
    this.currentPagePosition_.y =
      (this.canvasSize_.height - targetHeight) * 0.5

    this.currentPagePosition_.x -= bb.offset.left * this.currentPageScale_
    this.currentPagePosition_.y -= bb.offset.top * this.currentPageScale_

    this.currentElementCoordinatesOnCenterOfClientArea_.x = 0.5
    this.currentElementCoordinatesOnCenterOfClientArea_.y = 0.5
  }

  /**
   *
   * @param move 新しいX座標(clientX*dpr) 実ピクセル数
   */
  public movePagePosition (elements: Array<VisualElement | undefined>, move: PhysicalPosition): void {
    this.currentPagePosition_.x += move.x
    this.currentPagePosition_.y += move.y
  }

  /**
   * ページ描画位置を指定値に変更する
   * @param pos
   */
  public setPagePositon (elements: Array<VisualElement | undefined>, pos: PhysicalPosition): void{
    this.currentPagePosition_.x = pos.x
    this.currentPagePosition_.y = pos.y
  }

  /**
   *
   * @param scaleDiff 拡大差分 浮動小数
   * @param pivot スケール変更する前の拡大起点座標X HTML要素のoffsetXY（DPR考慮済み）
   * @returns
   */
  public changePageScale (elements: Array<VisualElement | undefined>, scaleDiff: number, minScale: number, maxScale: number, pivot?: PhysicalPosition): void {
    let targetScale = this.currentPageScale_ + scaleDiff * this.currentPageScale_
    if (targetScale < minScale) {
      targetScale = minScale
    } else if (targetScale > maxScale) {
      targetScale = maxScale
    }
    this.calcVirtualOrigin_(targetScale, pivot)
    this.currentPageScale_ = targetScale
  }

  /**
   * 描画拡大率を直接指定する
   * @param scale 新しいスケール値
   */
  public setPageScale (elements: Array<VisualElement | undefined>, scale: number): void{
    this.calcVirtualOrigin_(scale)
    this.currentPageScale_ = scale
  }

  private updateElementCoordinatesOnCenterOfClientArea (elements: Array<VisualElement | undefined>): void{
    if (!elements[0]) {
      return
    }
    const bb = this.getElementBoundingBox(elements, this.currentPageScale_, this.currentCropping_, false) // clippingは不要か？
    if (bb.width <= 0 || bb.height <= 0) {
      return
    }

    const x = this.canvasSize_.width * 0.5 - this.currentPagePosition_.x
    const y = this.canvasSize_.height * 0.5 - this.currentPagePosition_.y
    this.currentElementCoordinatesOnCenterOfClientArea_.x = x / bb.width
    this.currentElementCoordinatesOnCenterOfClientArea_.y = y / bb.height
  }

  public changeRotation (angle: number): boolean {
    if (angle < -360 || angle > 360) {
      return false
    }

    this.currentRotation_.angle = angle
    return true
  }

  public isFitScale (elements: Array<VisualElement | undefined>, binding: Binding): boolean {
    if (this.keepFitScale_) {
      return true
    }

    const fitScale = this.getFitScale(elements, binding)
    if (Math.abs(this.currentPageScale_ - fitScale) < 0.0001) {
      return true
    }

    return false
  }

  /**
   *
   * @param left mm
   * @param top mm
   * @param width mm
   * @param height mm
   * @returns
   */
  public setCroppingArea (left?: number, top?: number, width?: number, height?: number): boolean {
    if (left !== undefined && top !== undefined && width !== undefined && height !== undefined) {
      this.currentCropping_.left = left
      this.currentCropping_.top = top
      this.currentCropping_.right = width + left
      this.currentCropping_.bottom = height + top
    } else {
      this.currentCropping_ = new RectMM()
    }
    return true
  }
}

export { Layouter, MetaSearchHitPin }
