import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52031008"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "item-detail has-background-page",
  role: "main"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "item-viewer-not-found-item level-center is-mobile"
}
const _hoisted_4 = { class: "item-viewer-not-found-item-body has-background-contents shadow" }
const _hoisted_5 = {
  class: "title",
  "data-cy": "item-viewer-not-found-item-title"
}
const _hoisted_6 = {
  key: 0,
  id: "mobile-screen-div"
}
const _hoisted_7 = { id: "full-screen-div" }
const _hoisted_8 = {
  key: 2,
  id: "item-detail-information",
  class: "item-detail-information"
}
const _hoisted_9 = { class: "item-detail-main-column" }
const _hoisted_10 = { class: "item-detail-sub-column" }
const _hoisted_11 = { class: "item-detail-single-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadstoneItem = _resolveComponent("HeadstoneItem")!
  const _component_NotPublished = _resolveComponent("NotPublished")!
  const _component_SimViewDeny = _resolveComponent("SimViewDeny")!
  const _component_ViewerContainer = _resolveComponent("ViewerContainer")!
  const _component_InformationHeader = _resolveComponent("InformationHeader")!
  const _component_TheVideoViewer = _resolveComponent("TheVideoViewer")!
  const _component_Pane = _resolveComponent("Pane")!
  const _component_AddonView = _resolveComponent("AddonView")!
  const _component_Splitpanes = _resolveComponent("Splitpanes")!
  const _component_PanelMetadata = _resolveComponent("PanelMetadata")!
  const _component_PanelAbstracts = _resolveComponent("PanelAbstracts")!
  const _component_PanelBundleMetadata = _resolveComponent("PanelBundleMetadata")!
  const _component_PanelIndex = _resolveComponent("PanelIndex")!
  const _component_PanelShare = _resolveComponent("PanelShare")!
  const _component_PanelDownload = _resolveComponent("PanelDownload")!
  const _component_PanelRelatedItems = _resolveComponent("PanelRelatedItems")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isHeadstoneItem)
          ? (_openBlock(), _createBlock(_component_HeadstoneItem, {
              key: 0,
              item: _ctx.item,
              hasContentError: false
            }, null, 8, ["item"]))
          : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.showNotFound)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('label.documentNotFound')), 1)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.isMobile)
                      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
                          (_ctx.showNotPublished && _ctx.currentContentsBundle?.contents?.length)
                            ? (_openBlock(), _createBlock(_component_NotPublished, { key: 0 }))
                            : _createCommentVNode("", true),
                          (!_ctx.canSimView)
                            ? (_openBlock(), _createBlock(_component_SimViewDeny, { key: 1 }))
                            : (_ctx.state.completeFetchAccessTokens)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                  (_ctx.viewerType !== 'BranchViewer' && _ctx.isBundlePermission && _ctx.isContentPermission && _ctx.showViewer)
                                    ? (_openBlock(), _createBlock(_component_ViewerContainer, {
                                        key: 0,
                                        ref: "contentViewer",
                                        viewerType: _ctx.viewerType,
                                        item: _ctx.item,
                                        bundleIndex: _ctx.currentBundleIndex,
                                        "tab-view-flag": _ctx.state.completeFetchAccessTokens
                                      }, null, 8, ["viewerType", "item", "bundleIndex", "tab-view-flag"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("section", _hoisted_7, [
                      (_ctx.fullScreen && !_ctx.isIframePage)
                        ? (_openBlock(), _createBlock(_component_InformationHeader, {
                            key: 0,
                            showFullScreenHeader: _ctx.showFullScreenHeader,
                            hideFullScreenHeader: _ctx.hideFullScreenHeader,
                            "full-screen": _ctx.fullScreen,
                            item: _ctx.item,
                            "viewer-type": _ctx.viewerType,
                            class: _normalizeClass([{ 'full-screen-header': _ctx.fullScreenHeader }, "item-detail-information-full-screen-header"]),
                            onMousemove: _ctx.showFullScreenHeader,
                            onMouseleave: _ctx.hideFullScreenHeader
                          }, null, 8, ["showFullScreenHeader", "hideFullScreenHeader", "full-screen", "item", "viewer-type", "class", "onMousemove", "onMouseleave"]))
                        : _createCommentVNode("", true),
                      (!_ctx.isMobile)
                        ? (_openBlock(), _createBlock(_component_Splitpanes, {
                            key: 1,
                            class: _normalizeClass(["item-detail-item-viewer has-background-modest shadow", { 'is-iframe-page': _ctx.isIframePage }]),
                            onResize: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paneResizeHandler($event)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Pane, {
                                class: "item-detail-content-view",
                                size: _ctx.detailContentViewSize,
                                "min-size": "9",
                                id: "viewer-main-pane"
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.showNotPublished && _ctx.currentContentsBundle?.contents?.length)
                                    ? (_openBlock(), _createBlock(_component_NotPublished, { key: 0 }))
                                    : (!_ctx.canSimView)
                                      ? (_openBlock(), _createBlock(_component_SimViewDeny, { key: 1 }))
                                      : (_ctx.state.completeFetchAccessTokens)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                            _withDirectives(_createVNode(_component_TheVideoViewer, {
                                              key: _ctx.item.pid,
                                              item: _ctx.item,
                                              viewerType: _ctx.viewerType
                                            }, null, 8, ["item", "viewerType"]), [
                                              [_vShow, _ctx.viewerType === 'TheVideoViewer' && _ctx.showViewer]
                                            ]),
                                            (_ctx.viewerType !== 'TheVideoViewer' && _ctx.showViewer)
                                              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewerType), {
                                                  key: _ctx.item.pid + _ctx.currentBundleIndex,
                                                  item: _ctx.item,
                                                  ref: "contentViewer"
                                                }, null, 8, ["item"]))
                                              : _createCommentVNode("", true)
                                          ], 64))
                                        : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["size"]),
                              _createVNode(_component_Pane, {
                                size: _ctx.addonViewSize,
                                "min-size": _ctx.addonViewMinSize,
                                class: _normalizeClass([{ 'full-screen-header': _ctx.fullScreenHeader }, "item-detail-pane-addon-view"]),
                                id: "viewer-side-pane"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AddonView, {
                                    "tab-view-flag": _ctx.state.completeFetchAccessTokens,
                                    item: _ctx.item,
                                    "viewer-type": _ctx.viewerType,
                                    "show-not-published": _ctx.showNotPublished,
                                    "reset-token": _ctx.addonViewResetToken,
                                    class: "item-detail-addon-view",
                                    onOnClickAutoAdjust: _ctx.clickAutoAdjust
                                  }, null, 8, ["tab-view-flag", "item", "viewer-type", "show-not-published", "reset-token", "onOnClickAutoAdjust"])
                                ]),
                                _: 1
                              }, 8, ["size", "min-size", "class"])
                            ]),
                            _: 1
                          }, 8, ["class"]))
                        : _createCommentVNode("", true)
                    ]),
                    (!_ctx.isIframePage)
                      ? (_openBlock(), _createBlock(_component_InformationHeader, {
                          key: 1,
                          item: _ctx.item,
                          "viewer-type": _ctx.viewerType,
                          class: "item-detail-information-header"
                        }, null, 8, ["item", "viewer-type"]))
                      : _createCommentVNode("", true),
                    (!_ctx.isKn && !_ctx.isIframePage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_PanelMetadata, {
                              item: _ctx.item,
                              class: "item-detail-panel shadow"
                            }, null, 8, ["item"]),
                            (!_ctx.isMobile)
                              ? (_openBlock(), _createBlock(_component_PanelAbstracts, {
                                  key: 0,
                                  item: _ctx.item,
                                  class: "item-detail-panel shadow"
                                }, null, 8, ["item"]))
                              : _createCommentVNode("", true),
                            (Object.keys(_ctx.displayBundleMeta).length > 0)
                              ? (_openBlock(), _createBlock(_component_PanelBundleMetadata, {
                                  key: 1,
                                  meta: _ctx.displayBundleMeta,
                                  heading: _ctx.$t('informationPanel.bundleMetaSuffix', { name: _ctx.currentContentsBundle.name?.[_ctx.lang] }),
                                  class: "item-detail-panel shadow"
                                }, null, 8, ["meta", "heading"]))
                              : _createCommentVNode("", true),
                            (_ctx.isMobile)
                              ? (_openBlock(), _createBlock(_component_PanelIndex, {
                                  key: 2,
                                  item: _ctx.item,
                                  class: "item-detail-panel shadow"
                                }, null, 8, ["item"]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_PanelShare, {
                              item: _ctx.item,
                              class: "item-detail-panel shadow"
                            }, null, 8, ["item"]),
                            (_ctx.showDownloadPanel)
                              ? (_openBlock(), _createBlock(_component_PanelDownload, {
                                  key: 0,
                                  item: _ctx.item,
                                  class: "item-detail-panel shadow"
                                }, null, 8, ["item"]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_PanelRelatedItems, { class: "item-detail-panel shadow" })
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
            ], 512)), [
              [_vShow, _ctx.waitRestrictions]
            ])
      ]))
    : _createCommentVNode("", true)
}