import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-319a2b24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-viewer-contents-bundle-thumbnails" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "item-viewer-contents-bundle-thumbnails-message"
}
const _hoisted_4 = { class: "has-text-table-item-content" }
const _hoisted_5 = ["id"]
const _hoisted_6 = ["onClick", "onKeydown", "role", "aria-label"]
const _hoisted_7 = {
  key: 0,
  class: "has-background-page level-center is-mobile"
}
const _hoisted_8 = {
  key: 1,
  class: "has-background-page level-center is-mobile"
}
const _hoisted_9 = {
  key: 2,
  class: "has-background-page level-center is-mobile"
}
const _hoisted_10 = { class: "level is-mobile" }
const _hoisted_11 = ["title"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 2,
  class: "contents-list-bundle-name"
}
const _hoisted_14 = {
  key: 3,
  class: "has-text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloserController = _resolveComponent("CloserController")!
  const _component_IntersectionObserver = _resolveComponent("IntersectionObserver")!
  const _component_AppImg = _resolveComponent("AppImg")!
  const _component_DssIcon = _resolveComponent("DssIcon")!
  const _component_BookMarkButton = _resolveComponent("BookMarkButton")!
  const _component_FileContentsIndex = _resolveComponent("FileContentsIndex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        _ctx.contentsBundle.type === 'image' ||
          _ctx.contentsBundle.type === 'movie' ||
          _ctx.contentsBundle.type === 'audio'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.item.contentsBundles.length > 1)
            ? (_openBlock(), _createBlock(_component_CloserController, {
                key: 0,
                class: "contents-list-bundle-name",
                modelValue: _ctx.state.showThumbnails,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showThumbnails) = $event)),
                text: _ctx.contentsBundle?.name?.ja,
                "aria-controls": `contents-bundle-thumbnails-controls-${ _ctx.contentsBundle.id }`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.contentsBundle?.name?.ja), 1)
                ]),
                _: 1
              }, 8, ["modelValue", "text", "aria-controls"]))
            : _createCommentVNode("", true),
          (_ctx.contentsBundleMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.contentsBundleMessage), 1)
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_IntersectionObserver, { onIntersect: _ctx.intersectTop }, null, 8, ["onIntersect"]), [
            [_vShow, _ctx.state.showTntersectTop]
          ]),
          (_ctx.state.showThumbnails && _ctx.isBundlePermission)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["item-viewer-contents-bundle-body", _ctx.size]),
                id: `contents-bundle-thumbnails-controls-${ _ctx.contentsBundle.id }`
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortThumbnails, (thumbnail) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: thumbnail.value,
                    class: _normalizeClass([{ current: _ctx.current && Number(thumbnail.value) - 1 === _ctx.selectedItem, [_ctx.size]: true }, "item-viewer-contents-bundle-thumbnail"]),
                    onClick: ($event: any) => (thumbnail.enable && _ctx.selectItem(Number(thumbnail.value) - 1)),
                    onKeydown: _withKeys(_withModifiers(($event: any) => (thumbnail.enable && _ctx.selectItem(Number(thumbnail.value) - 1)), ["prevent"]), ["space","enter"]),
                    role: thumbnail.enable ? 'button' : '',
                    tabindex: "0",
                    "aria-label": (_ctx.contents[Number(thumbnail.value) - 1].name && _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang])? _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang] :  Number(thumbnail.value) - 1 + 1  
                  }, [
                    (thumbnail.enable && _ctx.isContentPermission(_ctx.contents[Number(thumbnail.value) - 1]))
                      ? (_openBlock(), _createElementBlock("figure", _hoisted_7, [
                          (_ctx.contentsBundle.type === 'image')
                            ? (_openBlock(), _createBlock(_component_AppImg, {
                                key: 0,
                                src: thumbnail.imageSrc,
                                alt: "",
                                type: "image"
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true),
                          (_ctx.contentsBundle.type === 'movie')
                            ? (_openBlock(), _createBlock(_component_AppImg, {
                                key: 1,
                                alt: "",
                                type: "video"
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.contentsBundle.type === 'audio')
                            ? (_openBlock(), _createBlock(_component_AppImg, {
                                key: 2,
                                alt: "",
                                type: "music"
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.isPlayingBundle && Number(thumbnail.value) - 1 === _ctx.playingItem && _ctx.isNowPlaying)
                            ? (_openBlock(), _createBlock(_component_DssIcon, {
                                key: 3,
                                id: "video-viewer-playing-icon",
                                icon: "speaker-wave-loud"
                              }))
                            : _createCommentVNode("", true)
                        ]))
                      : (_ctx.isPlayingBundle && Number(thumbnail.value) - 1 === _ctx.playingItem && _ctx.isNowPlaying && _ctx.isContentPermission(_ctx.contents[Number(thumbnail.value) - 1]))
                        ? (_openBlock(), _createElementBlock("figure", _hoisted_8, [
                            _createVNode(_component_DssIcon, {
                              id: "video-viewer-playing-icon",
                              icon: "speaker-wave-loud"
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("figure", _hoisted_9, [
                            _createVNode(_component_DssIcon, {
                              id: "deny-content-icon",
                              icon: "book-forbiddan",
                              style: {"font-size":"4rem"}
                            })
                          ])),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", {
                        class: "item-viewer-contents-bundle-content-name",
                        title: (_ctx.contents[Number(thumbnail.value) - 1].name && _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang])? _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang] :  Number(thumbnail.value) - 1 + 1  
                      }, _toDisplayString((_ctx.contents[Number(thumbnail.value) - 1].name && _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang])? _ctx.contents[Number(thumbnail.value) - 1].name?.[_ctx.lang] :  Number(thumbnail.value) - 1 + 1), 9, _hoisted_11),
                      (!_ctx.isKn && !_ctx.isRekion)
                        ? (_openBlock(), _createBlock(_component_BookMarkButton, {
                            key: 0,
                            pid: _ctx.pid,
                            bid: thumbnail.bid,
                            cid: thumbnail.cid,
                            isAddonViewContentsTab: "true"
                          }, null, 8, ["pid", "bid", "cid"]))
                        : _createCommentVNode("", true)
                    ])
                  ], 42, _hoisted_6))
                }), 128))
              ], 10, _hoisted_5))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_IntersectionObserver, { onIntersect: _ctx.intersectBottom }, null, 8, ["onIntersect"]), [
            [_vShow, _ctx.state.showIntersectBottom]
          ])
        ]))
      : (_ctx.contentsBundle.type === 'file' || _ctx.contentsBundle.type === 'content' )
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_ctx.item.contentsBundles.length > 1)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["contents-list-file-viewer-root", { current : _ctx.current && _ctx.currentFileDirectoryId === 'root' }])
                }, [
                  _createElementVNode("a", {
                    href: " ",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.changeFileViewerDirectory('root')), ["prevent"])),
                    onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_ctx.changeFileViewerDirectory('root')), ["prevent"]), ["space","enter"]))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.contentsBundle?.name?.ja), 1)
                  ], 32)
                ], 2))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.indexes?.children, (index) => {
              return (_openBlock(), _createBlock(_component_FileContentsIndex, {
                key: index.id,
                index: index,
                pid: _ctx.item.pid,
                contentsBundle: _ctx.contentsBundle,
                contentsBundleId: _ctx.contentsBundleId,
                currentFileDirectoryId: _ctx.currentFileDirectoryId,
                current: _ctx.current,
                onUpdateViewer: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateViewer(_ctx.i)))
              }, null, 8, ["index", "pid", "contentsBundle", "contentsBundleId", "currentFileDirectoryId", "current"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.contentsBundle?.name?.ja), 1)),
    (!_ctx.contentsBundle.contents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('rightPane.noContents')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}